import React from 'react';

const phone = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="vuesax_outline_call" data-name="vuesax/outline/call" transform="translate(-108 -188)">
        <g id="call">
          <path id="Vector" d="M16.2,21.5a9.149,9.149,0,0,1-3.55-.79,18.775,18.775,0,0,1-3.59-2.06,31.215,31.215,0,0,1-3.37-2.87,29.876,29.876,0,0,1-2.87-3.36A18.662,18.662,0,0,1,.78,8.86,9.259,9.259,0,0,1,0,5.29,6.026,6.026,0,0,1,.41,3.08,5.341,5.341,0,0,1,1.75,1.14,3.632,3.632,0,0,1,4.34,0,2.7,2.7,0,0,1,5.47.25a2.288,2.288,0,0,1,.96.81L8.75,4.33a3.874,3.874,0,0,1,.48.85,2.246,2.246,0,0,1,.2.89,2.122,2.122,0,0,1-.32,1.1,4,4,0,0,1-.67.85l-.68.71a.488.488,0,0,0,.03.07,7.69,7.69,0,0,0,.82,1.11c.49.56.95,1.07,1.41,1.54.59.58,1.08,1.04,1.54,1.42a6.513,6.513,0,0,0,1.16.83l-.02.05.73-.72a3.735,3.735,0,0,1,.9-.69,2.111,2.111,0,0,1,1.95-.11,4.553,4.553,0,0,1,.84.47l3.32,2.36a2.149,2.149,0,0,1,.8.95,2.894,2.894,0,0,1,.22,1.08,3.335,3.335,0,0,1-.32,1.41,4.657,4.657,0,0,1-.8,1.2,5.129,5.129,0,0,1-1.91,1.37A5.9,5.9,0,0,1,16.2,21.5ZM4.34,1.5a2.192,2.192,0,0,0-1.55.72,3.836,3.836,0,0,0-.98,1.41A4.375,4.375,0,0,0,1.5,5.29a7.71,7.71,0,0,0,.66,2.98,16.693,16.693,0,0,0,1.88,3.26,27.666,27.666,0,0,0,2.71,3.18,28.156,28.156,0,0,0,3.19,2.72,16.248,16.248,0,0,0,3.29,1.89,5.964,5.964,0,0,0,4.63.35,3.692,3.692,0,0,0,1.37-.99,3.243,3.243,0,0,0,.56-.84,1.772,1.772,0,0,0,.18-.77,1.194,1.194,0,0,0-.11-.5.757.757,0,0,0-.28-.3l-3.32-2.36a2.867,2.867,0,0,0-.55-.31.526.526,0,0,0-.65.03,2.28,2.28,0,0,0-.58.45l-.76.75a1.425,1.425,0,0,1-1.45.3L12,15.01A8.525,8.525,0,0,1,10.58,14c-.48-.41-1-.89-1.63-1.51-.49-.5-.98-1.03-1.49-1.62A8.532,8.532,0,0,1,6.44,9.46l-.12-.3a1.867,1.867,0,0,1-.08-.5,1.283,1.283,0,0,1,.38-.93l.75-.78a2.9,2.9,0,0,0,.45-.56.641.641,0,0,0,.11-.34.93.93,0,0,0-.08-.32,2.869,2.869,0,0,0-.32-.53L5.21,1.92a.922.922,0,0,0-.37-.31A1.252,1.252,0,0,0,4.34,1.5ZM12.7,13.76l-.16.68.27-.7A.123.123,0,0,0,12.7,13.76Z" transform="translate(109.25 189.25)" fill="CurrentColor" />
          <path id="Vector-2" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(108 188)" fill="none" opacity="0" />
        </g>
      </g>
    </svg>
  );
};

export default phone;
