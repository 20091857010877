import React from 'react';

const Envelope = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="vuesax_outline_sms-edit" data-name="vuesax/outline/sms-edit" transform="translate(-748 -252)">
        <g id="sms-edit">
          <path id="Vector" d="M10.75,18.5h-5C2.1,18.5,0,16.4,0,12.75v-7C0,2.1,2.1,0,5.75,0h10C19.4,0,21.5,2.1,21.5,5.75v3a.75.75,0,0,1-1.5,0v-3c0-2.86-1.39-4.25-4.25-4.25h-10C2.89,1.5,1.5,2.89,1.5,5.75v7C1.5,15.61,2.89,17,5.75,17h5a.75.75,0,0,1,0,1.5Z" transform="translate(749.25 254.75)" fill="CurrentColor" />
          <path id="Vector-2" data-name="Vector" d="M5.753,4.626a3.717,3.717,0,0,1-2.34-.79l-3.13-2.5A.748.748,0,0,1,.163.286a.757.757,0,0,1,1.05-.12l3.13,2.5a2.386,2.386,0,0,0,2.81,0l3.13-2.5a.739.739,0,0,1,1.05.12.738.738,0,0,1-.12,1.05l-3.13,2.5A3.67,3.67,0,0,1,5.753,4.626Z" transform="translate(754.247 260.244)" fill="CurrentColor" />
          <path id="Vector-3" data-name="Vector" d="M1.4,8.328a1.406,1.406,0,0,1-1-.4A1.423,1.423,0,0,1,.018,6.7l.19-1.35a1.981,1.981,0,0,1,.51-1.02L4.258.788a2.382,2.382,0,0,1,1.46-.78,2.264,2.264,0,0,1,1.82.78,2.252,2.252,0,0,1,.78,1.82,2.46,2.46,0,0,1-.78,1.46L4,7.608a1.981,1.981,0,0,1-1.02.51l-1.35.19A1.785,1.785,0,0,1,1.4,8.328ZM5.888,1.5h-.03a1.049,1.049,0,0,0-.54.35l-3.54,3.54a.381.381,0,0,0-.08.17l-.18,1.25,1.25-.18a.556.556,0,0,0,.17-.09L6.478,3a1.089,1.089,0,0,0,.35-.54c.02-.2-.18-.44-.35-.61A1.02,1.02,0,0,0,5.888,1.5Z" transform="translate(762.422 265.452)" fill="CurrentColor" />
          <path id="Vector-4" data-name="Vector" d="M2.967,3.727a.645.645,0,0,1-.2-.03A3.977,3.977,0,0,1,.027.957a.76.76,0,0,1,.52-.93.745.745,0,0,1,.92.52,2.465,2.465,0,0,0,1.7,1.7.755.755,0,0,1,.52.93A.747.747,0,0,1,2.967,3.727Z" transform="translate(765.953 266.522)" fill="CurrentColor" />
          <path id="Vector-5" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(748 252)" fill="none" opacity="0" />
        </g>
      </g>
    </svg>

  );
};

export default Envelope;
