import React, { useState } from 'react';
import axios from "axios";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import styled from 'styled-components';

import InputField from './FormFields/Input/Input';
import Textarea from './FormFields/Textarea/Textarea';
import Button from '../Basic/Button';
import Col from '../Basic/Col';
import Row from '../Basic/Row';

const validateSchema = Yup.object().shape({
  name: Yup.string()
    .max(40, 'Too Long!')
    .required('Required!'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Required!'),
  phone: Yup.string()
    .min(4, 'Too Short!')
    .max(18, 'Too Long!'),
  message: Yup.string()
    .min(10, 'Too Short!')
    .max(38, 'Too Long!')
    .required('Required!'),
});

const StyledForm = styled(Form)`
  width: 100%;
`;

const StyledInfoSuccess = styled.div`
  width: 100%;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  font-weight: 600;
  color: #fff;
  background-color: ${({ theme }) => theme.infoColor.success};
`;

const StyledInfoError = styled.div`
  width: 100%;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  font-weight: 600;
  color: #fff;
  background-color: ${({ theme }) => theme.infoColor.danger};
`;

const StyledAnchor = styled.a`

&:hover{
  color: ${({ theme }) => theme.neutralColor.textPrimary};
}
`;

const ContactForm = () => {

  const { executeRecaptcha } = useGoogleReCaptcha();
  const [mailSent, setMailSent] = useState(false);
  const [error, setError] = useState(null);

  return (
    <Formik
      initialValues={{
        name: '',
        email: '',
        phone: '',
        message: '',
      }}
      validationSchema={validateSchema}
      onSubmit={(values, { setSubmitting }) => {
        console.log('sending...');
        if (!executeRecaptcha) {
          console.log('captha fail...');
          return
        }
        // const result = await executeRecaptcha('homepage');
        // console.log('result: ', result);
        executeRecaptcha('homepage')
          .then((token) => {
            axios({
              method: "post",
              url: 'https://jukonmedia.pl/api/contact/index.php', //change this to correct endpoint
              headers: { "content-type": "application/json" },
              data: {
                mailSent: mailSent,
                error: error,
                name: values.name,
                email: values.email,
                phone: values.phone,
                msg: values.message,
                token: token
              }
            })
              .then(result => {
                console.log(result);
                if (result.data.sent) {
                  setMailSent(result.data.sent);
                  setError(false);
                } else {
                  setError(true);
                }
              })
              .catch(error => setError(error.message));
            setSubmitting(false);
          })
          .catch((err) => {
            console.log(err);
          });
      }}
    >
      {({ isSubmitting }) => (
        <StyledForm>
          <Row>
            <Col md={4}>
              <InputField type="text" name="name" customplaceholder="Imię*" />
            </Col>
            <Col md={4}>
              <InputField
                type="email"
                name="email"
                customplaceholder="Email*"
              />
            </Col>
            <Col md={4}>
              <InputField
                type="tel"
                name="phone"
                customplaceholder="Numer Telefonu"
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Textarea name="message" customplaceholder="Wiadomość*" />
            </Col>
            <Button
              round="5px"
              margin="30px 0"
              width="100%"
              type="submit"
              className="button arrow_right"
              disabled={isSubmitting}
            >
              Wyślij
            </Button>
            <div style={{ width: '100%', display: 'flex' }}>
              {mailSent && <StyledInfoSuccess>Wiadomość poprawnie wysłana</StyledInfoSuccess>}
              {error && <StyledInfoError>Błąd wysyłania wiadomości</StyledInfoError>}
            </div>
            <div>
              <p style={{ fontSize: '12px', }}>
                * - Pola wymagane.
              </p>
              <p style={{ fontSize: '12px', }}>
                Ta strona jest chroniona przez reCAPTCHA i Google. Sprawdź <StyledAnchor target="_blank" href="https://policies.google.com/privacy">Politykę Prywatności</StyledAnchor> oraz <StyledAnchor target="_blank" href="https://policies.google.com/terms">Warunki Użytkowania</StyledAnchor>.
              </p>
            </div>
          </Row>
        </StyledForm>
      )}
    </Formik>
  );
}

export default ContactForm;
